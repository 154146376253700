$(document).on('turbolinks:load', function() {
  drawTable('ass', 'attack');
  drawTable('ass', 'host');
  drawTable('countries', 'attack');
  drawTable('countries', 'host');
});

function drawTable(type, count) {
  var xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200 ) {
      if (this.responseText) {
        var response = JSON.parse(this.responseText);
        for (data of response) {
          html = '<tr>'
          html += '<td>' + data['name'] + '</td>'
          html += '<td>' + data['value'] + '</td>'
          html += '</tr>'
          $('.section--table__' + type + '__' + count + ' table ').append(html);
        }
      }
    }
  }
  xmlHttpRequest.open('GET', 'https://ynu-iot-data.s3-ap-northeast-1.amazonaws.com/' + type + '/table/' + count + '/current.json', true);
  xmlHttpRequest.send(null);
}
