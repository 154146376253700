$(document).on('turbolinks:load', function() {
  google.charts.load('current', {'packages':['corechart']});
  google.charts.setOnLoadCallback(drawChart);

  dataNum = 365;

  $(function() {
    $('.section--chart__period').change(function() {
      dataNum = $('.section--chart__period option:selected').val();
      drawChart();
    });
  });
});

function drawChart() {
  var xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200 ) {
      if (this.responseText) {
        var rawData = JSON.parse(this.responseText);
        var colors = [
          '#005bac',
        ];

        // 表示しない期間のデータを削る
        if (rawData.length - 1 > dataNum) {
          header = rawData[0];
          rawData = rawData.slice(dataNum * (-1));
          rawData.unshift(header);
        }

        // 積み上げ面グラフの境界線の太さを指定する
        // see alse: https://developers.google.com/chart/interactive/docs/gallery/columnchart
        serviceNum = rawData[0].length - 1;
        for (i = 0; i < serviceNum; i++) {
          rawData[0].splice(serviceNum + 1 - i, 0, {role: "style"});
        }
        for (i = 1; i < rawData.length; i++) {
          for (j = 0; j < serviceNum; j++) {
            rawData[i].splice(serviceNum + 1 - j, 0, 'stroke-width: 1');
          }
        }

        var data = google.visualization.arrayToDataTable(rawData);
        var options = {
          legend: {position: 'none'},
          vAxis: {minValue: 0},
          colors: colors,
          backgroundColor: '#eee',
          isStacked: true,
          chartArea: {top: '5%', bottom: '15%' ,left: '8%', right: '5%'}
        };
        var chart = new google.visualization.AreaChart(document.getElementById('chart'));
        google.visualization.events.addListener(chart, 'select', function () {
          select = chart.getSelection();
          // legend が選択された場合
          // see also: https://qiita.com/hippocampus-coronatus/items/3f31148ab538eb4d1bd0
          if (select.length > 0 && select[0].row === null) {
            // stub
          }
        });
        chart.draw(data, options);
      }
    }
  }
  xmlHttpRequest.open('GET', 'https://ynu-iot-data.s3-ap-northeast-1.amazonaws.com/malwares/chart/current.json', true);
  xmlHttpRequest.send(null);
}
