$(document).on('turbolinks:load', function() {
  drawValue();
});

function drawValue() {
  var xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200 ) {
      if (this.responseText) {
        data = JSON.parse(this.responseText);
        $('.stats__value--total-malware-samples').html(data['total_malware_samples']);
        $('.stats__value--unique-malware-samples').html(data['unique_malware_samples']);
        $('.stats__value--total-malware-download-urls').html(data['total_malware_download_urls']);
        $('.stats__value--unique-malware-download-urls').html(data['unique_malware_download_urls']);
      }
    }
  }
  xmlHttpRequest.open('GET', 'https://ynu-iot-data.s3-ap-northeast-1.amazonaws.com/malwares/value/current.json', true);
  xmlHttpRequest.send(null);
}
