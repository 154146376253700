$(document).on('turbolinks:load', function() {
  var xmlHttpRequest = new XMLHttpRequest();
  xmlHttpRequest.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200 ) {
      if (this.responseText) {
        data = JSON.parse(this.responseText);

        date = new Date (data['updated_at'] * 1000);
        dateStr = date.getFullYear() + '/'+ ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2)
        $('.section--map__description__last-update').text('Last update: ' + dateStr);

        attackData = data['data'];
        $('#vmap').vectorMap({
          map: 'world_en',
          backgroundColor: '#fff',
          borderWidth: 0.5,
          borderColor: '#ddd',
          color: '#ddd',
          hoverColor: null,
          selectedColor: null,
          enableZoom: false,
          onLabelShow: function(event, label, code) {
            countryName = label[0].innerHTML;
            value = attackData[code] === undefined ? 0 : attackData[code];
            label.html('<div class="map-tooltip" style="margin: 10px"><p class="description">' + countryName + '<br>' + value.toLocaleString() + '</p></div>');
          },
        });

        var max = 0,
            min = 0,
            cc,
            startColor = [221, 221, 221], // #cccccc
            endColor = [0, 91, 172], // #005bac
            colors = {},
            hex;

        //find maximum value
        for (cc in attackData)
        {
            if (parseFloat(attackData[cc]) > max)
            {
                max = parseFloat(attackData[cc]);
            }
        }

        //set colors according to values of DoS Data
        for (cc in attackData)
        {
            if (attackData[cc] > 0)
            {
                colors[cc] = '#';
                for (var i = 0; i<3; i++)
                {
                    hex = Math.round(startColor[i]
                        + (endColor[i]
                        - startColor[i])
                        * Math.pow(((attackData[cc] - min) / (max - min)), 1/3)).toString(16);

                    if (hex.length == 1)
                    {
                        hex = '0'+hex;
                    }

                    colors[cc] += (hex.length == 1 ? '0' : '') + hex;
                }
            }
        }

        $('#vmap').vectorMap('set', 'colors', colors);
      }
    }
  }
  xmlHttpRequest.open('GET', 'https://ynu-iot-data.s3-ap-northeast-1.amazonaws.com/countries/map/current.json', true);
  xmlHttpRequest.send(null);
});
